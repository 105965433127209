// prettier-ignore
export const ENGLISH_TRANSLATIONS = {
  // Platform
  platform: {
    general: {
      downloadCsv: "Download CSV",
      downloadXlsx: "Download XLSX",
      download: "Download",
      downloadFile: "Download File",
      averageTime: "Average Time",
      onTimeDeliveries: "On time Deliveries",
      invoicedAmount: "Invoiced Amount",
      orders: "Number of orders",
      averagePrice: "Average price per shipment",
      averageCost: "Average Cost",
      returns: "Returns",
      return: "Return",
      onTime: "On time deliveries",
      serviceLevel: "Service Level",
      viewInvoice: "View Invoice",
      settings: "More Filters",
      apply: "Apply",
      cancel: "Cancel",
      close: "Close",
      weekly: "Weekly",
      monthly: "Monthly",
      total: "Total",
      perCarrier: "By Carrier",
      perIssue: "By Issue",
      perState: "By Status",
      loading: "Preparing for takeoff!",
      downloadCSV: "Please wait a few seconds whilst we gather your data.",
      asanaForm: "Asana Form",
      noData: "There are no records to show",
      addFeatures: "Add Features",
      getStarted: "Get Started",
      hi: "Hi",
      currentYear: "Current Year",
      month: "Month",
      savings: "Savings",
      currency: "Currency",
      percentage: "Percentage",
      cost: "Cost",
      of: "of",
      or: "or",
      unknown: "Unknown",
      collapseMenu: "Collapse Menu",
      documentationLinkText: "here",
      unassigned: "Unassigned",
    },
    files: {
      "generated_invoice": "Generated Invoice",
      "invoice": "Invoice",
      "sales_invoice": "Invoice",
      "invoice_file": "Invoice",
      "damaged_pictures": "Damaged Pictures",
      "damaged_picture": "Damaged Picture",
      "proof_of_honor": "Proof of Honor",
      "proof_of_honour": "Proof of Honor",
      "identity_document": "Proof of Indentity",
      "other": "Other",
      "claim_payment_paperwork": "Claim Payment Paperwork",
      "subscription_file": "Subscription File",
    },
    deliveryIssues: {
      lost: "Lost",
      lateDelivery: "Late Delivery",
      damaged: "Damaged",
      deliveryDispute: "Delivery Dispute",
      labelNotUsed: "Label Not Used",
      refundsOnInternationalDeliveries: "Refunds on International Deliveries",
      unknown: "Unknown",
      freight: "Freight",
      tax: "Tax",
      charges: "Charges",
      incident: "Incident",
    },
    onBoarding: {
      title: "Your account has been successfully created! Let's set it up now.",
      description: "In order to process your carrier information and start realising savings,  we will need some information regarding your carriers.",
      team: {
        title: "Provide your team members with access.",
        noResults: "No members saved at this point",
      },
      details: {
        title: "No worries, you will not be invoiced before receiving your savings. This is just for us to have the most updated information. You will have the opportunity to update it at any moment on the platform.",
        inputs: {
          legalEntity: "Legal Entity",
          country: "Country",
          city: "City",
          address: "Address",
          postalCode: "Postal Code",
          vat: "VAT Number",
          emails: "Bills reception email(s)",
          selectCountry: "Select Country",
          typeName: "Type name",
          typeEmail: "Type email",
          typeVat: "Type VAT Number",
          typeAddress: "Type your address",
          inputCity: "Input your city",
          logo: "Add Company Logo",
          logoDescription: "Drag and drop image here or click the select file. Add image of maximum size up to 5mb.",
        },
        errorDescriptions: {
          oneImage: "Only one image can be uploaded."
        },
      },
      banking: {
        details: "To ensure seamless transactions and processing of refunds, please provide your banking details.",
        title: "Bank Account Details",
      },
    },
    productTour: {
      home: {
        step_one_title:
          "Welcome to the <span style='color: #ff5b6d; font-weight: 500;'>Lox platform</span>,",
        step_one_textBlock:
          "My name is Isis and I will be guiding you through the Lox platform today! <br /> Enjoy the tour!",
        step_two_textBlock:
          "<p>In the menu you will find shortcuts to different locations within our platform.</p><p>Here you will have access to different aspects of your logistical profile.</p>",
        step_three_title: "<p style='color: #ff5b6d'>Disclaimer:</p>",
        step_three_textBlock:
          "<p>These two products are not live yet.</p> <p> Coming soon!</p>",
        step_four_textBlock:
          "<p>Found any bug or looking for a new feature? Let us know here.</p> <p>Together the carriers will never stand a chance!</p>",
        step_five_textBlock:
          "<p>By clicking on your profile, you find access to:<p><ul><li>Updating Profile</li><li>Managing Carrier Invoices</li><li>Updating your Team</li><li>Starting this tour.</li><li>Logging out</li></ul>",
        step_six_title: "Top overview:",
        step_six_textBlock: [
          "<p>Here you can find the main information about",
          "invoices after our auditing process.</p><p>Click on any of the cards to get a breakdown on the numbers.",
        ],
        step_seven_textBlock:
          "These charts give you a top-level overview on what you pay your carriers.",
      },
      refund: {
        step_one_title:
          "Welcome to the <span style='color: #ff5b6d; font-weight: 500;'>Refund Page</span>,",
        step_one_textBlock:
          "<p>Here you will find a complete breakdown of the refunds Lox has identified and acted on.",
        step_two_textBlock:
          "On the first tab you will find an overview of the refunds we identified.",
        step_three_textBlock:
          "The second one will give you a more in-depth persepective of the refunds on an order level.",
        step_four_textBlock:
          "<p>Here you can filter the Refunds data by different criteria.</p><p>Perfect for any in-depth analysis.",
      },
      credentials: {
        step_one_title: [
          "Hello ",
          " and Welcome to the <span style='color: #ff5b6d; font-weight: 500;'>Lox Platform</span>.",
          "I'm Isis and I will guide you through this process. In order to get your account set-up, we will need to have access to your carrier invoices",
          "I'm Isis and I will guide you through this process. Here you will be able to upload your Carrier Invoices for Lox to audit.",
        ],
        step_two_textBlock:
          "You can either add your carrier invoice portal credentials.<p></p>At Lox, we will be able to pick up your carrier invoice every time a new invoice is available.",
        step_three_textBlock:
          "You can also upload your carrier invoices here. <br></br>We take any format of document.",
        step_four_textBlock:
          "Here you can find a log of your previous uploaded invoices.",
        step_five_textBlock:
          "Once you have added your credentials or uploaded your invoices, we will start doing our magic!",
        engaged: {
          step_one_title: [
            "Hello ",
            " and Welcome to your <span style='color: #ff5b6d; font-weight: 500;'>Carrier Credentials</span>.",
            "I'm Isis and I will guide you through this process. We will need your credentials in order to download your Carrier Invoices or simply upload them <a style='color: #ff5b6d; font-weight: 500;' href='/carrier/invoices'>HERE</a>",
          ],
          step_two_textBlock:
            "Here you will have an overview of the carrier invoice portal credentials you created.<p></p>At Lox, we will be able to pick up your carrier invoice every time a new invoice is available.",
        },
      },
      members: {
        step_one_title:
          "Many thanks for the <span style='color: #ff5b6d; font-weight: 500;'>Carrier Invoices</span>.",
        step_one_textBlock:
          "On this page, you can add your team members to the Lox platform. Feel free to invite as many as you want. ",
        next_process:
          "Once you have made your changes click here to continue the process!",
        engaged: {
          step_one_title: "Welcome to your <span style='color: #ff5b6d; font-weight: 500;'>Team Members</span> view."
        },
      },
      changePassword: {
        step_one_title: [
          "Welcome to the <span style='color: #ff5b6d; font-weight: 500;'>Lox Platform</span>.",
          "Welcome to your <span style='color: #ff5b6d; font-weight: 500;'>Profile Settings</span>.",
        ],
        step_one_textBlock: [
          "<p>Here you can manage all your personal information.</p><p>We recommend that you change the password registered on your name currently.</p>",
          "<p>Here you will be able to manage all your personal information.</p>",
        ],
        step_two_textBlock:
          "Once you have made your changes click here to continue the process!",
      },
    },
    updateTour: {
      home: {
        cardOne: {
          title: "Platform Updates 🌟",
          text: "Take the product tour to know recent changes made to our platform!",
        },
        cardTwo: {
          title: "Side Panel",
          text: "You can easily access all categories from the side bar here!",
        },
        cardThree: {
          title: "My Invoices",
          text: "You can access a new page to manage all your carrier invoices!",
        },
        cardFour: {
          title: "My Carrier Information",
          text: "There are more updates made on My Carrier Information page. You can view it by clicking here!",
        },
      },
      carrierInformation: {
        cardOne: {
          title: "My Carrier Information",
          text: "On this dashboard you can overview and manage all you carriers!",
        },
        cardTwo: {
          title: "Add Carrier",
          text: "You can add new carriers by uploading information such as credentials and invoices.",
        },
        cardThree: {
          title: "Carrier Scope",
          text: "You can manage your existing carrier information here!",
        },
      },
      myInvoices: {
        cardOne: {
          title: "My Invoices",
          text: "On this dashboard you can overview all your carrier invoices!",
        },
        cardTwo: {
          title: "Download invoices",
          text: "You can download the details of each one of them in CSV format.",
        },
        cardThree: {
          title: "Saved Amount",
          text: "Here you can see how much money is saved per invoice!",
        },
      },
    },
    newUpdates: {
      home: {
        comingSoon: {
          title: "New home page coming soon!",
          text: "In a few days you'll be witnessing a brand new home page on our platform.",
        },
        landed: {
          title: "Home Page has Landed!",
          text: "Discover our re-designed Home Page and enjoy a smoother and an intuitive user experience.",
        },
      },
      dataNeeded: {
        comingSoon: {
          title: "Investigation Support Document Page is coming soon.",
          text: "We're enhancing the bulk upload flow from Action Required for a better user experience. Look out for the new version next week.",
        },
        landed: {
          title: "New page live!",
          text: "Discover a new and easy way to bulk upload your packages information via CSV or XLSX format.",
        },
      },
    },
    noResults: {
      title: "No Results to display",
      text: "Currently applied filters:",
    },
    profile: {
      title: "Profile Settings",
      personalTitle: "Personal Info",
      passwordTitle: "Change Password",
      newUser: "Add a team member",
      sendInvite: "Send Invitation(s)",
      sendInvites: "Send Invitations",
      forgotPassword: "Forgot your password?",
      firstName: "First Name",
      lastName: "Last Name",
      company: "Company",
      email: "Email",
      alreadyLogged: "Active User",
      activeUser: "Active",
      unactiveUser: "Inactive",
      currentPassword: "Current Password",
      password: "New Password",
      passwordVerification: "Confirm Password",
      updateProfile: "Update my profile",
      verification: {
        newPassword:
          "Enter at least 8 letters, including 1 capial letter and 1 number",
        samePassword: "Please make sure your passwords match.",
      },
    },
    members: {
      invitations: {
        title: "Invite(s) in Queue",
        queued: "In Queue",
        processing: "Processing",
        sent: "Sent",
        failed: "Failed",
        invite: "Invite",
      },
    },
    tasks: {
      title: "My tasks",
      subTitle:
        "Download the list of shipments that requires sales invoices to finalise the refund process and drop them in the box!",
      leftPanel: "Number of shipments waiting for sales invoices :",
      leftPanelButton: "Download the list of shipments here",
      righPanel:
        "Upload your sales invoices in pdf, png or jpg - with your order reference as title:",
      rightPanelDrop: "Drop your sales invoices here",
    },
    invoices: {
      title: "My Lox Invoices",
      description: "Here you can view, explore and manage your invoices.",
      subTitle: "Invoices",
      titleInvoice: "Invoice Details",
      invoicedTo: "Invoice to",
      btw: "BTW",
      invoiceNumber: "Invoice Number",
      acctNumber: "Account Number",
      vatNumber: "Vat Number",
      invoiceDate: "Invoice Date",
      cocKvK: "COC/KVK",
      orderDetails: "Refund Details",
      vat: "VAT",
      total: "Total",
      summary: "Summary",
      popup: "Please wait 3 seconds, and your invoice will be ready.",
      vatText: "",
      files: "Use CSV,Exel or XML files.",
      dropFiles: "Drag and Drop or",
      selection: "choose",
      fileType: "files",
      selectFile: "Select File",
      templateType: "filled template.",
      findFiles: "choose files",
      uploaded: "files uploaded",
      uploading: "Uploading { total } Documents",
      pleaseWait: "Please wait this might take a minute.",
      noData: "No documents have been added",
    },
    parameters: {
      title: "Parameters",
      titleExpand: "Parameters - Click to expand",
      dateFrom: "From",
      dateTo: "To",
      date: "Date",
      value: "Value",
      transitTime: "Transit Time ( Days )",
      onTime: "On Time ( % )",
      carriers: "Carriers",
      carrier: "Carrier",
      choice: "Choice",
      countrySender: "Country Sender",
      countryReceiver: "Country Receiver",
      problemsEncountered: "Problems Encountered",
      typeDelivery: "Delivery Type",
      serviceLevel: "Service Level",
      selectAll: "Select All",
      clear: "Clear",
      run: "Refresh",
    },
    placeholder: {
      trackingNumbers: "Type a Tracking Number",
      references: "Type an order number",
      invoiceNumbers: "Type an invoice number",
      statementNumber: "Type a statement number",
      search: "Start typing to search",
      issue: "State your issue",
      carrier: "Select a Carrier",
      refunds: "Select an Issue",
      tracking_number: "000001234",
      nb_of_items: "For Eg: 5",
      unitPrice: "For Eg: 120",
      content: "For Eg: Clothes, Batteries",
      cost_merchandise: "€25.00",
      net_amount: "€25.00",
      amount_incl: "€29.95",
      tax_percentage: "21",
      sales_date: "YYYY/MM/DD",
      selectCompanies: "Select Companies",
      emailTypes: "Select Email Send Outs",
      firstName: "Enter members first name",
      lastName: "Enter members last name",
      memberEmail: "Enter members email",
      email: "Type your email",
      password: "Type your Password",
      firstName: "Your Name",
      lastName: "Your Last Name",
      company: "Company Name",
      jobTitle: "Job Title",
      phone: "111 555 33 77",
      url: "https://",
      portal_url: "Type the URL for the carrier platform",
      username: "Type your username",
      invoiceName: "Name as appears on invoice",
      receptionEmail: "Email for receiving invoices",
      country: "Select a country",
      vat: "Type VAT number",
      city: "Type your city",
      zip: "e.g. 89381-6757",
      street: "Street, building, apartment",
      comment: "Tell us about any specificities that you might have with this carrier",
      searchCarrier: "Search Carrier...",
      typeCarrier: "Type Carrier Name",
      multiSearch: "Search tracking number, carrier or reference",
      downloadFile: "Download File As:",
      quantity: "2",
      startTyping: "Start Typing...",
      filters: {
        carrier: "Filter by carrier",
        issue: "Filter by issue",
        state: "Filter by state",
        stage: "Filter by stage",
        owner: "Filter by owner",
      },
      selectCarrierFirst: "Select a carrier to view claimable issues",
      noInvoices: "No Invoice Attached",
      noImage: "No Images Attached",
      bank_name: "ING Bank NV",
      bank_account_number: "12345678910",
      IBAN: "NL20INGB0001234567",
      BIC: "INGBNL2A",
    },
    csv: {
      carrier: "carrier",
      description: "description",
      amountPaid: "amount_paid",
      postalCode: "postal_code_receiver",
      countryReceiver: "country_code_receiver",
      countrySender: "country_code_sender",
      trackingNumber: "Tracking Number",
      netAmount: "net_amount",
      label: "label",
      orders: "orders",
      averagePaid: "avg_price",
      serviceLevel: "service_level",
      invoiceDate: "Invoice Date",
      invoiceNumber: "Invoice Number",
      reference: "Order Number",
      totalPrice: "Total Price",
      reasonRefund: "Issue Spotted",
      state: "State",
      creditInvoice: "credit_invoice_number",
      weight: "weight",
      height: "height",
      length: "length",
      width: "width",
      accountNumber: "account_number",
      quantity: "quantity",
      return: "returns",
      claimDate: "Claim Date",
    },
    filterForm: {
      title: "Filter Selection",
    },
    arrayColumns: {
      costs: "Costs",
      cost: "Cost",
      costPercentage: "Cost %",
      returnPercentage: "Return %",
      amountPaid: "Amount Paid",
      paidMoreThan: "Paid more than:",
      postal: "Zone/Postal",
      description: "Description",
      carriers: "Carrier",
      orders: "Orders",
      order: "Order",
      day: "day",
      lastUpdate: "Last Update",
      admin: "Admin",
      serviceLevels: "Service Level",
      invoiceDate: "Invoice Date",
      claimDate: "Investigation Date",
      invoiceNumber: "Invoice Number",
      creditInvoiceNumber: "Credit Invoice Number",
      orderReference: "Order Reference",
      reference: "Order No.",
      trackingNumber: "Tracking No.",
      customDetail: "Custom",
      totalPrice: "Total Price",
      totalCost: "Total Cost",
      monthlyTotal: "Monthly Total",
      monthlySavings: "Monthly Savings",
      valueInTotal: "Value in Total",
      averagePaid: "Average Paid",
      totalOrders: "Total Orders",
      problems: "Issue",
      avgShipment: "Avg per Shipment",
      portalUrl: "URL link",
      link: "Link",
      states: "State",
      height: "Height",
      length: "Length",
      width: "Width",
      countriesSent: "Countries Sent",
      countriesReceived: "Countries Received",
      accountNumbers: "Account Number",
      numberOfPackages: "Quantity",
      returns: "Returns",
      netAmount: "Net Amount",
      weight: "Weight",
      edit: "Edit",
      update: "Update",
      upload: "Upload",
      documents: "Documents",
      fileUpload: "Upload New Documents",
      addDocument: "Add Document",
      uploadInvoices: "Latest Uploaded Invoices",
      uploadAnInvoice: "Upload An Invoice",
      uploadTracking: "Upload invoice for ",
      uploadBelow: " below",
      uploadFiles: "Latest Uploaded Files",
      credentials: "Credentials",
      updateCarrier: "Update Credentials",
      deleteCarrier: "Delete Credentials",
      receivedInvoices: "Invoice Received",
      type: "Type",
      delete: "Delete",
      new: "New",
      username: "Username",
      password: "Password",
      urlPortal: "Url Portal",
      dueDate: "Due Date",
      amount: "Amount",
      savedAmount: "Saved Amount",
      dueAmount: "Due Amount",
      totalAmount: "Total Amount",
      status: "Status",
      reason: "Reason",
      creditDate: "Credit Date",
      quantity: "Quantity",
      discount: "Discount",
      fee: "Fee",
      from: "from",
      to: "to",
      shipments: "# Shipments",
      lane: "Lane",
      ship: "Shipments",
      label: "Label",
      freightPaid: "Amount Paid Freight",
      surchargesPaid: "Amount Paid Surcharges",
      percentage: "Percentage",
      all: "All",
      top5: "Top 5",
      freight: "Freight",
      last30Days: "Last 30 days",
      last90Days: "Last 90 days",
      last12Months: "Last 12 Months",
      lastYear: "Last Year",
      totalPercentage: "of Total",
      columns: "Add Columns",
      uploadedDate: "Uploaded date",
      uploadDate: "Upload Date",
      access: "Access",
      filename: "File Name",
      name: "Name",
      claimedBy: "Claimed By",
      bothCompanies: "Both Companies",
      unit: "units",
      wrongCredential: "Active Credential",
      active: "Credential Working",
      notActive: "Credential not Working",
      notWorking: "Not Working",
      daysLeft: "Days left to Claim",
      claimableReason: "Reason Claimable",
      days: "Days",
      uploaded: "Uploaded!",
      actions: "Actions",
      comment: "Leave Comment",
      downloadInvoice: "Download Invoice",
      downloadDetails: "Download Details",
      actionNeeded: "Action Needed",
      issue: "Issue",
      statement: "Statement",
      statementDetails: "Statement Details",
      statements: "Statements",
      statementNumber: "Statement Number",
      statementDate: "Statement Date",
      monthMissing: "Month Missing",
      uploadPoaDocument: "Upload POA document",
      stage: "Stage",
      action: "Action",
      details: "Details",
      change: "Change",
      owner: "Owner",
    },
    spend: {
      overviewTitle: "These are the metrics that apply to the dates you selected",
    },
    credentials: {
      value: "Credentials",
      title: "My Carrier Credentials",
      subTitle: "Carrier Credentials",
      subTitleSentence:
        "You don't have all the information? No worries you can still upload or edit them later after saving.",
      credentialForm: "Add {carrier} carrier",
      uploadInvoices: "Upload your carrier invoices or contracts",
      uploadFiles: "Search",
      confirmFiles: "Confirm Invoices",
      dropHere: "Drop your carrier invoices here",
      newCredentials: "Add another credential",
      otherCarrier: "Other Carrier",
      fileName: "File Name",
      invoiceDoc: "Invoice",
      contract: "Contracts",
      credit: "Credit Note",
      other: "Other",
      progressText: "Files Uploaded:",
      waitForProgress: "Please wait whilst we upload all your documents.",
      selectCarrier: "Select Carrier",
      addTeam: "Add your team",
      companyDetails: "Company details",
      additionalInfo: "Additional Information",
      invoice: "Carrier Invoice",
      platformTitle: "Platform Credentials",
      documentTitle: "Carrier document",
      requestPerSection: "Saving Information per Section",
      integrated: "integrated by lox",
      comingSoon: "Available Soon",
      main: "Add new carrier",
      description: "Please select your carrier and follow the instructions.",
      myCarriers: "My Carriers",
      noCarriers: "You don’t have any carriers yet. Add the first one to start save your money with Lox",
      addCarrier: "Add Carrier",
      selectType: "Select Type",
      loadingDocuments: "Please wait whilst we upload your files",
      active: "On",
      inactive: "Credentials not working",
      processed: "Processed",
      notProcessed: "Not Processed",
      noData: "No credentials have been added",
      displayNoData: "There is currently no information available yet. To add a carrier and their information just click",
      getStarted: "And get started!",
      missing: "Missing",
    },
    refunds: {
      overview: "Overview",
      details: "Details",
      moreDetails: "More Details",
      lostData: "Lost Data",
      myClaims: "Start an investigation",
      investigationDetails: "Investigation Details",
      all: "All",
      byLox: "By Lox",
      calendarFilter: "Filter on {filter}",
      invoiceDate: "Invoice Date",
      claimDate: "Investigation Date",
      removeGood: "Remove Good",
      uploadInvestigation: "Upload investigation support document",
      noUpdateAvailable: "No update available",
      status: "Status",
      incidentExplanation: "This investigation was found on the carrier platform and the delivery issue type could not be retrieved. Please consult the history for more information.",
      unknownExplanation: "We are currently uncertain about the status of this investigation. Kindly provide any additional information and update the investigation.",
      betaUpdates: "BETA Lastest Updates",
      investigationHistory: {
        title: "Investigation History",
        trackingNo: "Tracking No.",
        investigationNumber: "Investigation Number",
        investigationCreatedDate: "Investigation Created Date",
        issueType: "Issue Type",
        amountCreditedValueOfInvestigation: "Amount Credited/Value of Investigation",
        attachedDocuments: "Attached Documents",
      },
      actionRequiredModal: {
        title: "Upload Documents",
        subTitle: "You can upload a document up to 5 Mb max. Only supports {types} format.",
        carrier: "Last Carrier Message",
        drag: "Drag and drop file here, or",
        click: "click here to add file,",
        message: "Insert your message here",
      },
      emptyTable: {
        actionRequired: {
          title: "You are all done",
          text: "There are no actions needed at the moment on any investigation",
        },
        noInvestigations: {
          title: "No investigation available",
          text: "It seems that there is no existing investigation. Start opening your investigation now!",
        },
        carrierInformation: {
          text: "There are no actions needed at the moment on any credentials",
        },
        activeFiltersNoResults: {
          title: "No investigation found",
          text: "No investigation found matching the applied filters."
        },
      },
      notifyUserModal: {
        confirmation: {
          title: "Warning",
          explanation: "Are you sure you want to stop the ongoing investigation? Once it has stopped, you cannot edit, change or restart this investigation.",
          buttonLabel: "Stop Investigation",
        },
        success: {
          title: "Success",
          explanation: "This investigation is successfully stopped. You can still access the history of this investigation.",
          buttonLabel: "Close",
        },
        loading: {
          explanation: "Stopping your investigation, this may take a few seconds...",
        },
      },
      actionRequired: {
        pageBanner: {
          invoice_missing: {
            title: "Merchandise Information",
            text: "These investigations require package information to be processed.",
          },
          documents_missing: {
            title: "Documents",
            text: "These investigations require additional documents to be processed.",
          },
          pictures_missing: {
            title: "Pictures",
            text: "All investigation which require images for processing the investigation.",
          },
          unknown: {
            title: "Unknown",
            text: "All the ongoing investigations which require user action.",
          },
          carrier_information: {
            title: "Carrier Information",
            text: "Some of the Carriers are missing information to process investigations.",
          },
        },
        modal: {
          invoiceMissing: {
            title: "Upload Missing Invoice",
            subTitle: "Upload {carrier} invoice, for the month of {month}",
            acceptedTypes: "Accepted file types: {fileTypes}",
          },
          updateCredential: {
            title: "Update Faulty Credential",
            subTitle: "Fix the faulty credential by keeping information up to date."
          },
        },
      },
      bulkUpload: {
        title: "Bulk Upload",
        subTitle: "Submit your data in bulk by uploading a CSV/XLSX formatted file.",
        explanation: {
          first: "In case you need detailed information about the feature, refer ",
          second: "All the information regarding the errors and how to fix them, can be found in this ",
          third: "Please follow the following steps to successfully submit the file.",
          linkOne: "here",
          linkTwo: "documentation",
        },
      },
      singleUpload: {
        title: "Single Upload",
        subTitle: "Upload the supporting document for a single tracking number.",
      },
      identifiedIssues: "Issues Identified",
      uploadExplanation: "Upload all your data in bulk to start the investigations.",
      singleExplanation: "Upload Support documents manually to individual tracking number to start an investigation.",
      claims: {
        createButton: "Start an investigation",
        updateClaim: "Update Investigation Information",
        tableTitle: "Here you can create your own claim for the carrier via Lox platform.",
        popInstructions: "Please, select carrier and refund reason first.",
        sendClaim: "Create Claim",
        invoiceDescription: "Drag and drop file here or click the Select File button. Add file in {types} formats of maximum size 5 Mb.",
        newClaim: "Send Claim",
        addImage: "Add Photos",
        product_invoice: "Add Invoice",
        salesInvoice: "Sales Invoice",
        addSalesInvoice: "Add Sales Invoice",
        addOtherDocuments: "Add Supporting Documents",
        imageDescription: "Drag and drop files here or click the Select File button. Add files in png, jpeq or jpg formats of maximum size 5 Mb.",
        selectClaim: "Select a Claim",
        details: "Merchandise Details",
        documents: "Add Documents",
        submit: "Submit",
        submitSection: "Submit",
        uploadFile: "Upload CSV / XLSX File",
        matchHeaders: "Match Headers",
        validateData: "Validate Data",
        uploadingAnalysis: "Uploading",
        claimDetailsDescription: "Uploading all details attached to this claim e.g carrier, issue etc",
        documentsDescription: "Uploading all documents attached to this claim.",
        otherDocuments: "Other Documents",
        damagedImages: "Damaged Images",
        noData: "There are no claims for you to see yet! You can start creating claims",
        stats: {
          all: "All Investigations",
          progress: "In progress",
          credited: "Refund Overview",
          saved: "Amount Saved",
          declined: "Declined",
          notOpen: "Not Open to the Carrier",
          ongoing: "Ongoing Investigations",
          closed: "Closed Investigations",
          descriptions: {
            all: "All investigations started till date.",
            notOpen: "All investigations waiting to start at the carrier portal",
            ongoing: "All ongoing investigations at the carrier portal",
            closed: "Investigations which are closed and no action is further taken",
            credited: "Total Credits of all Investigations",
          },
        },
        autofillDetails: "Autofill details (API/SFTP)",
        autofillHovered: {
          activated: "With data integration autofill enabled, we'll gather merchandise information. You can enter merchandise details manually by disabling the auto-fill option.",
          deactivated: "No data integration seems to be available. Establish the implementation to enable effortless autofill of merchandise details.",
        },
        noDetails: {
          noData: "No data to display",
          reason: "There was an error retrieving your history. Please try again at a later time."
        },
        investigationCreation: {
          successful: "Investigation Creation - Successful",
          failed: "Investigation Creation - Failed",
          claimAlreadyExisting: "Investigation Creation - Claim Already Existing",
        },
        investigationUpdate: {
          successful: "Investigation Update - Successful",
          failed: "Investigation Update - Failed",
        },
        investigationReminder: {
          successful: "Investigation Reminder - Successful",
        },
        clientDataConnection: {
          successful: "Retrieval of Sales Information From API/SFTP - Successful",
          failed: "Retrieval of Sales Information From API/SFTP - Failed",
        },
        editInvestigation: {
          successful: "Edit Investigation - Successful",
          failed: "Edit Investigation - Failed",
          received: "Edit Investigation - Received",
        },
        message: {
          from: "Message from {carrier}",
          to: "Message to {carrier}",
          userinput: "Reply sent from Lox platform"
        },
        updateInvestigation: "Update Investigation",
      },
      investigations: {
        breadcrumbs: [
          "Upload CSV/ XLSX File",
          "Match Headers",
          "Fix Error Lines",
          "Submit File",
        ],
        title: "Pre Import Instructions",
        subTitle: "Please follow the following steps to successfully submit the file.",
        uploadAreaLabel: "Upload Your File",
        uploadArea: "Drag and drop file here or click the Upload button. Add file in csv/xlsx/xls formats, maximum size up to 5 Mb.",
        tip: {
          text: "In case you need detailed information about the feature, refer ",
        },
        documentation: {
          text: "All the information regarding the errors and how to fix them can be found in",
          boldText: "this documentation",
        },
        notifications: {
          favourite: {
            title: "New favorite investigation",
            text: "You just added this investigation as favorite. You'll receive mail notifications every time an update is available. You can undo it by removing the favorite label.",
          },
          unFavourite: {
            title: "Investigation unfavoured",
            text: "This investigation has been removed from your favorites. You will no longer receive update notifications for this investigation",
          },
        },
        steps: [
          "Download the .xlsx or .csv template",
          "Fill the template",
          "Upload the file below",
          "Validate all data",
          "Fix all errors",
          "Submit the correct file",
        ],
        errors: {
          fileType: "File doesn't match the following formats: XLSX, XLS or CSV",
          insufficientColumns:{
            update: "The uploaded file does not contain atleast 8 columns.",
            start : "The uploaded file does not contain atleast 4 columns.",
          },
          insufficientMatchedColumns: " columns remain unmatched",
          noDataInFile: "File uploaded with no information.",
        },
        columnMatching: {
          title: "Match Column Headers",
          subTitle: "Match the column headers in your file to the appropriate column headers in the Lox template. Do not keep any column headers blank.",
          selectOption: "Select Option",
          selectFormat: "Select Date Format",
          headers: {
            carrier: "Carrier Name",
            reference: "Order Number",
            trackingNumber: "Tracking Number",
            salesDate: "Sales Date",
            description: "Product Description",
            quantity: "Quantity",
            totalPrice: "Selling Price",
            currencyCode: "Currency Code",
            vat: "Tax Percentage",
            check: "Check",
            issue: "Issue",
          },
          table: {
            headers: {
              user: "Columns Headers in your Document",
              lox: "Column Headers in Lox Template",
              sample: "Sample Data",
            },
          },
        },
        previousStep: {
          warning: "Warning data will be lost",
          text: "You will be taken back to the column matching section.",
          confirmButton: "Confirm data loss",
        },
        validateData: {
          title: "Validate Column Entries",
          subTitleOne: "Our system checks that the values of each rows are meeting the requirements.",
          subTitleTwo: "You can click on each cell and correct the data. Alternatively you can also proceed with only correct entries.",
          showOnlyErrors: "Only show entries with an error",
          showMore: "Show More",
          removedTN: " packages were removed due to unvalidated tracking numbers. Add them via",
          noData: {
            title: "No valid tracking numbers found in the file uploaded.",
            text: "The tracking numbers in the template do not match against the one uploaded.\nPlease provide the details only for the tracking numbers within the csv/xls template.",
            solution: "For adding new tracking numbers, start new investigation via the button below",
          },
          trackingNumbersRemoved: {
            duplicate: "We spotted duplicate tracking number in the file. Please find them listed in this <span class='link'>export</span>",
            invalid: "We spotted tracking number for which an investigation is already open. Please find them listed in this <span class='link'>export</span>. You can access more informations in the investigation details page.",
          },
          hoverMessages: {
            alert: "Alert",
            error: "Error",
            rangeError: "Date Outside of Accepted Ranged",
            valueError: "Invalid Cell Value",
            carrierWarning: "Invalid Carrier Credentials",
            formatError: "Invalid Cell Format",
            zeroWarning: "Zero Value Cell",
            amountWarning: "Significant Amount",
            emptyCurrency: "Default Currency",
            carrierError: "Invalid Carrier Cell",
            NullError: "This cell is found to be empty. In order to continue the investigation process for this package we require you to provide the necessary information.",
            InvalidNumberError: "This cell is found to have an incorrect value. We expect the information provided to be of a number format. Please edit this cell to have a number value.",
            AmountConfirmation: "This cell is found to have a value that exceeds our standard threshold. Please be sure that the amount displayed is correct.",
            ZeroBasedConfirmation: "This cell is found to have a value of 0. This value is accepted however, it is not a standard value so please be sure it is correct.",
            NegativeNumberError: "This cell is found to have a negative value. We expect that the information provided should not be of a negative value. Please edit this cell to have a non-negative value.",
            ZeroBasedError: "This cell is found to have a value of 0. In this cell we do not accept 0 as a value. Please edit this cell with a value of more than 0.",
            DecimalError: "This cell is found to have a decimal value. In this cell we only accept whole numbers ( 1 and more ). Please edit this cell to have a whole number value.",
            DateRangeError: "This cell was found to have a value that falls outside of the accepted date range. Edit this cell to have a date value between: {0} and {1}.",
            InvalidDateError: "This cell was found to have a value that doesn't match the date format yyyy-mm-dd ( 2023-09-23 ). Please edit this cell to match the format yyyy-mm-dd ( 2023-09-23 ).",
            invalidCarrier: "This cell was found to have a value that isn't a valid due to either it not being a carrier name or your not registered to your account.",
            invalidCurrency: "This cell was found to have a value that isn't a valid currency code. Please refer to the accepted currencies in the dropdown provided.",
            emptyCurrencyDescription: "This cell was found to be empty. It will be filled with the default currency of Euro, if this isn't the desired currency please fill in the currency of your choosing.",
            carrierCredentialWarning: "This carrier's credentials are no longer working. To process the data given please update them in your carrier information page.",
            lengthErrorDescription: "This cell was found to have a value that does not meet the required length. Please edit this cell to have a value with more than { length } characters.",
            invalidIssueError: "This cell was found to have a value that isn't a valid issue. Please make sure the value is either Lost, Damaged, Late Delivery or Delivery Dispute.",
            duplicateTrackingNumberError: "We located more than one occurence for this tracking number. Please fill in the correct Issue.",
            invalidCarrierError: "This cell was found to have a value that isn't a valid carrier name. Please make sure the value is a valid carrier name.",
            invalidCarrierIssue: "The carrier cell was found to have an invalid carrier name. Please update carrier name first, in order to collect the valid list of issues for that carrier."
          },
          overviewBlocks: {
            loading: "Checking for Errors...",
            warning: {
              singular: "Warning",
              plural: "Warnings",
            },
            error: {
              singular: "Error",
              plural: "Errors",
            },
            valid: " lines are validated",
          },
          popMessages: {
            processing: "Processing",
            success: {
              title: "The upload was successful!",
              updationText: "The details have been successfully submitted for the tracking numbers!",
              creationTextAPI: "Your tracking number will be available in the Action Required Page within 24 hours where you'll be able to insert the merchandise info.",
              creationText: "Your tracking number will be available in the Action Required Page within 24 hours where you’ll be able to insert the merchandise info.",
            },
            error: {
              title: "seems like there was an error in submitting the File!",
              text: "Don't worry all your changes have been saved. Please try to submit the file after a short while!",
            },
          },
        },
        submitFile: {
          downloadError: "Download Error File",
          proceedWithValid: "Proceed with correct entries only",
          removeFile: "Remove File",
          upload: "Upload File",
        },
        details: {
          message: "Message",
          lastUpdated: "Last Updated",
          uploadDocument: "Upload Document",
          noHistory: {
            not_open: "No investigation history is available at the moment.",
            error: "There was an error retrieving your history. Please try again at a later time.",
          },
        },
      },
      bulkStart: {
        toggles: {
          bulk: {
            title: "Create investigations in bulk",
            description: "Create investigations in bulk by uploading a CSV/XLSX formatted file.",
          },
          single: {
            title: "Create a single investigation",
            description: "Create and submit  a single investigation at a time",
          },
        },
      },
      uploadSelection:
        "Choose the appropriate way to upload your product data.",
      API: "API Integration",
      API_description:
        "Setting up an API integration with Lox is easy and quickly done. This is the most sustainable and effective approach.",
      manual: "Manual Uploading",
      manual_description:
        "Upload the necessary product data manually, by filling in a template or uploading documents.",
      downloadHelp: "Download API documentation",
      apiHelper: {
        paragraph_1:
          "An API integration is the most effective and sustainable approach. It takes only a few hours to set-up by your technical team. We advice to share the API documentation with your technical team. ",
        paragraph_2:
          "Once the API is set-up, you can click on \"My API is set-up\". We will make sure everything goes smoothly from there.",
      },
      actionHelp: {
        title: "Our team is here for you 🌟",
        text: "Please, tell us what is your blocker?",
        button: "I need help"
      },
      actionEdit: {
        button: "Edit My API Config",
      },
      actionSetup: {
        title: "Set up API Config",
        text: "Please fill in the necessary information below to help us connect to your API.",
      },
      actionApply: "My API is Set Up",
      actionBack: "Back to Selections",
      cancelApi: "Cancel API integration",
      disconnectApi: "Disconnect my API",
      apiStaging: {
        processing: {
          line_1: "Our team is currently finalizing your API.",
          line_2: "We'll contact you soon.",
        },
        success: {
          line_1: "Your API is currently working perfectly.",
          line_2: "Check the results in the details tab.",
        },
      },
      apiConnected: "API Connected",
      apiFailed: {
        title: "Request Failed",
        disconnect: "API failed to disconnect. Please try again and if there problem presists please contact us via customersuccess@loxsolution.com",
        connect: "API failed to connect. Please try again, if the problem presists please screenshot the page and create an Asana Ticket on the board Website."
      },
      manualHelper: {
        firstParagraph: "In the table you will find packages that have the potential of being claimed, however to do so we require more information on these packages. This will help Lox build a comprehensive claim report for that package.",
        secondParagraphHeader: "Upload an invoice per tracking number",
        secondParagraph: "By clicking the 'upload' call to action you will be presented with a form. All fields are required, in order to complete the uploading process. Once uploaded within 24 hours results will be present on the My Refunds Page.",
        bulkHeader: "Bulk Uploading",
        bulkParagraph: {
          stepOne: "By clicking the 'Bulk Uploading' call to action you will, be presented with a form.",
          stepTwo: "Here you will be able to download a template in either csv or xlsx and re-upload that template.",
          stepThree: "Once the file is downloaded, fill in the information for as many tracking numbers as possible, be aware you don't have to complete all the tracking numbers to upload the document.",
          stepFour: "Once uploaded within 24 hours results will be present on the My Refunds Page.",
        },
      },
      manualAction: "Upload invoices",
      hoverTitle: " tracking numbers are waiting for their invoice.",
      missing: "Action Required",
      apiMissing: "We couldn't find anything for these tracking numbers. Please, upload it manually.",
      howItWorks: "How it works",
      bulkUploadTitle: "Bulk Uploading CSV",
      bulkSubList: {
        one: "the template and fill in the blanks.",
        two: "Upload the filled in template below.",
        three: "Encountering Errors?",
        find: "Find data setting details here"
      },
      error: {
        title: "Explanation of Errors",
        headers: "Incorrect Headers",
        date: "Incorrect Date Format",
        noData: "No Data in File",
        noFile: "No File Upoaded",
        extraTrackingNumbers: "Extra Tracking Numbers",
        multipleProducts: "Multiple product in one box",
        acceptedCharacters: "Accepted Characters",
        do: "Do",
        dont: "Don't",
        description: {
          headers: "Do not customise the column headers as above.",
          extraTrackingNumbers: "Adding extra tracking numbers other than the ones pre-filled in the list will result in errors during the upload process.",
          multipleProducts: "If a single tracking number contains multiple products in a package, don't add the description of all items within the same cell",
          acceptedCharacters: "Inserting characters not accepted in one column will be preventing the file from being processed.",
          date: {
            do: "Ensure that the date format matches the required format YYYY-MM-DD",
            dont: "Do not add the date in different formats other than the one specified",
          },
          noData: {
            do: "If a bulk template is uploaded in CSV, XLS, or XLSX format, all fields for each tracking number must be fully filled in.",
            dont: "Incomplete information for any tracking number may result in errors during the upload process.",
          },
        },
      },
      correct: {
        headers: "Correct Headers",
        date: "Correct Date Format",
        noData: "Correctly Uploaded Data",
        extraTrackingNumbers: "No Extra Tracking Numbers",
        description: {
          headers: "Make sure your column headers match the ones in the Lox template sheet.",
          extraTrackingNumbers: "Make sure that there are no extra tracking numbers added to the list other than the ones pre-filled in the xlsx or csv template you downloaded.",
          multipleProducts: "Please make sure to input one product per line. If you had different product in your package you can duplicate the line for your tracking number and list the other product information (product name, quantity, total price etc). You can do it as many time as you want.",
          acceptedCharacters: [
            "Please find below the character accepted per column:",
            "Sales Date: Numeric characters, / and - are accepted",
            "Product Description: all characters are accepted",
            "Quantity: Only numeric characters are accepted",
            "Total price: Numeric characters, , and . are accepted",
            "VAT: Numeric characters, , , . and % are accepted",
          ],
        },
      },
      filter: "Filters",
      monthly: "Monthly Credits",
      weekly: "Weekly Credits",
      invoicedAmount: "Invoiced Amount",
      refundableAmount: "Refundable Amount",
      requestedAmount: "Requested Amount",
      confirmedAmount: "Confirmed Amount",
      creditedAmount: "Credited Amount",
      tableTitle: "Refunds Table",
      investigationsBreakdown: "Investigations Breakdown",
      investigationsSplit: "Investigations Split",
      refundBreakdown: "Breakdown of Credits",
      process: {
        requested:
          "This is the amount that Lox has identified incorrectly billed and has requested at the carrier.",
        confirmed:
          "This is the requested amount that has been confirmed by the carrier, but has not been credited yet.",
        credited:
          "This is the credited or saved amount. You can find the saving back in your bank account or as credit note.",
      },
    },
    billing: {
      subscription: "Subscription plan overview",
      totalVolumeAnalysed: "Total Volume Analysed",
      information: "Billing Information",
      tableTitle: "Invoices, credits notes and statements",
      bankAccountDetails: "Bank Account Details",
      labels: {
        companyName: "Company Name",
        subscriptionPrice: "Subscription Price",
        paymentFrequency: "Billing Cycle",
        bundle: "Bundle Capacity",
        startDate: "Start Date",
        endDate: "End Date",
      },
      tableHeaders: {
        select: "Select",
        exactInvoiceNumber: "Document No.",
        invoiceDate: "Date",
        documentType: "Document Type",
        amount: "Amount",
        dueDate: "Due Date",
        status: "Status of Payment",
        action: "Download",
      },
      tableCells: {
        documentType: {
          creditNote: "Credit Note",
          statement: "Statement",
          invoice: "Invoice",
        },
        status: {
          due: "Due",
          paid: "Paid",
          overdue: "Overdue",
        },
      },
      hoverMessage: [
        "You have reached 80% of your subscription capacity. Please ",
        "contact customer support ",
        "to buy extra bundle.",
      ],
      breakdownTitles: {
        carrier: "Credit Usage per Carrier",
        month: "Credit Usage per Month",
      },
      overdueInvoice: "It looks like one or several due payments have not been received. Without any payment received we'll have to suspend your account for all your users.",
    },
    team: {
      inQueue: "Invite(s) in Queue",
      queue: "In Queue",
      processing: "Processing",
      sent: "Sent",
    },
    custom: {
      title: "Custom",
    },
    homepage: {
      title: "Welcome back, ",
      subtitle: "Here is your delivery overview for the past 12 months to current date",
      monthlyInvoices: "Monthly Invoices",
      weeklyInvoices: "Weekly Invoices",
      carrierBreakdown: "Carrier Breakdown",
      plan: "Upgrade your plan",
    },
    home: {
      blockDescriptions: {
        myClaims: "Click to Start new Investigations",
        investigationDetails: "Details of all ongoing investigations",
        actionRequired: "User actions required on investigations",
      },
    },
    sidebar: {
      home: "Home",
      claimCenter: "Claim Center",
      dataNeeded: "Data Needed",
      uploadInvestigation: "Investigations Support Documents",
      myClaims: "Start an investigation",
    },
    topbar: {
      profile: "My Profile",
      credentials: "My Carrier Information",
      carrierInvoices: "Upload Carrier Documents",
      tour: "Tour",
      members: "Team Members",
      files: "My Files",
      invoices: "Billing and Subscription",
      logout: "Sign Out",
      notificationsTitle: "Notifications",
      notificationsSubTitle: "New Tasks received",
      notificationsTextBefore: "You have",
      notificationsTextAfter:
        "invoices to upload in order to get all your refunds.",
      satisfaction: "Customer Satisfaction",
      satisfaction_text:
        "<p>Please choose one to log out.</p> <p>Help us better understand how your current session has been.<br /> See you soon!</p>",
    },
    month: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    database: {
      carrier: "Carrier",
      description: "Description",
      country_code_receiver: "Country Received",
      country_code_sender: "Country Sender",
      postal_code: "Postal Code",
      postal_code_receiver: "Postal Code Receiver",
      tracking_number: "Tracking Number",
      reference: "Reference",
      is_return: "Returned",
      net_amount: "Selling Price",
      total_net_amount: "Selling Price",
      service_level_agreement: "Service Level",
      orders: "Orders",
      avg_paid: "Average Cost",
      cost_merchandise: "Total price of goods ( Tax excl. )",
      nb_of_items: "No. of Goods",
      unitPrice: "Unit Selling Price ( Tax excl. )",
      sales_date: "Date of sale",
      user: "User",
      reason_refund: "Issue",
      invoice_url: "Uploaded Invoice",
      supporting_document_urls: "Uploaded Supporting Documents",
      damaged_urls: "Uploaded Photos",
      content: "Description of Goods",
      lost: "Lost",
      damaged: "Damaged",
      lateDelivery: "Late Delivery",
      labelNotUsed: "Label Not Used",
      residentialSurcharges: "Residential Surcharges",
      freight: "Freight",
      charges: "Charges",
      tax: "Tax",
      credited: "Credited",
      confirmed: "Confirmed",
      requested: "Requested",
      requestedMyClaims: "Processing",
      refundable: "Refundable",
      declined: "Declined",
      actionNeeded: "Action Needed",
      processing: "Processing",
      overdue: "Overdue",
      delivered: "Delivered",
      not_eligible: "Not eligible",
      investigation_stopped: "Investigation Stopped",
      returned_to_sender:"Return to sender",
      on_time: "Delivered on time",
      stopped: "Stopped",
      impossible_to_open: "Impossible to open",
      invoice_missing: "Merchandise info needed",
      picture_missing: "Pictures needed",
      documents_missing: "Documents needed",
      pictures_missing: "Pictures needed",
      original_invoice_missing: "Original invoice missing",
      ongoing: "Ongoing",
      closed: "Closed",
      unknown: "Unknown",
      not_open: "Not open",
      request_for_label_not_used: "Request for label not used",
      percentage: "Percentage of Total",
      number_of_packages: "Number of Orders",
      amount: "Total Amount",
      avg_cost: "Average Cost per Order",
      amount_excl: "Total Amount (excl Tax)",
      amount_incl: "Total Amount (incl Tax)",
      account_number: "Account Number",
      discount: "Discount",
      height: "Height",
      incentive_amount: "Incentive Amount",
      invoice_number: "Invoice Number",
      invoice_date: "Invoice Date",
      lead_tracking_number: "Lead Tracking Number",
      length: "Length",
      quantity: "Quantity",
      type_charges: "Type Charges",
      type_weight: "Type Weight",
      url: "URL",
      weight: "Weight",
      width: "Width",
      zone: "Zone",
      invoice_company_name: "Invoicing Name",
      vat_number: "Vat number",
      invoicing_emails: "Invoice reception email",
      finance_email_address: "Invoice reception email",
      country: "Country",
      city_name: "City",
      street_name: "Address",
      zip_code: "Postal/ZIP code",
      state: "State",
      tax_percentage: "Tax %",
      currency_code: "Currency",
      bank_name: "Bank Name",
      bank_account_number: "Bank Account Number",
      IBAN: "IBAN",
      BIC: "BIC",
      late_delivery: "Late Delivery",
      label_not_used: "Label Not Used",
      delivery_dispute: "Delivery Dispute",
      credit_month: "Credit Date",
      total_price: "Total Price",
    },
  },
  dashboard: {
    stats: {
      spend: {
        title: "Total Spend",
        link: "Explore",
      },
      savings: {
        title: "Total Savings",
        link: "Explore",
      },
      shipments: {
        title: "Number of Shipments",
        link: "Explore",
      },
    },
    custom: {
      x_axis: {
        title: "X-Axis Data",
        link: "Explore",
      },
      y_axis: {
        title: "Y-Axis Data",
        link: "Explore",
      },
      graph: {
        title: "Visualize Data",
        link: "Explore",
      },
      filters: {
        title: "Filters to Apply",
        link: "Explore",
      },
    },
  },
  // Front Page
  menu: {
    home: "home",
    solution: "solution",
    pricing: "pricing",
    contact: "contact",
    team: "team",
    login: "Login",
    book: "Book a Demo",
  },
  landing: {
    nav: {
      dashboard: "Dashboarding",
      refunds: "Refunds",
      cases: "Case Studies",
      products: "Products",
      vacancies: "Careers",
      english: "English",
      french: "French",
    },
    dashboard: {
      dashboardTitle: [
        "Powerful",
        "Dashboards",
        " that provide clarity on your logistics",
      ],
      dashboardSub:
        "Finally, all your insights concerning your last-mile shipments in one place!",
      carouselTitle: "Popular Carriers Intergrations",
      controlArea: "Control everything in a single place",
      noCode: "Start without any code today",
      deliveryCarriers: "Share your carrier credentials",
      dashboardRunning: "Your dashboard is up and running !",
      insightsTitle: "All insights at your finger tips",
      clockCardTitle: "Your logistical performance made clear",
      clockCardBody:
        "All the insights concerning your surcharges, on-time delivery rate and more made transparent",
      activityCardTitle: "Easily drilldown to and compare",
      activityCardBody:
        "Sharpen your analysis by filtering on country, date range many more options",
      documentCardTitle: "Easy to start - just Plug & Play",
      documentCardBody:
        "We only need to have your carrier invoices to start. Simple as that!",
      pricingPlanTitle: "One tool for your whole team",
      pricingPlanSub:
        "A pricing plan that fits your needs based on your shipping profile. Just select how many shipments you send a year.",
      pricingPlanMobile:
        "A pricing plan that fits your needs based on your shipping profile. Book a call with one of our advisors to discover the price.",
      rangeDetail: "Packages / month",
      customDetail: "Custom",
      rangeValue: "per shipment",
      pricingServicesTitle: "Services Included",
      serviceList: [
        "Life time access",
        "Shipping profile transparent",
        "All your carriers in one place",
        "No code integration",
        "Multi-user access",
        "24/7 phone and email support",
      ],
      pricingStart: "Book a demo",
      teamTitle: "All your Carriers Integrated",
      teamSub: "A solution for all your carriers.",
      newTeamTitle: "The Lox Team",
      newTeamSub:
        "The most important thing to us is building products people love",
      faqTitle: "Frequently Asked Questions",
      exchangeTitle: "Let's exchange!",
      exchangeSub: "We'll get back to you within 24 hours",
      emailPlaceholder: "Your email",
      messagePlaceholder: "Your message",
      messageSender: "Send",
    },
    refund: {
      refundTitle: ["Always pay the", "correct amount", " to your carriers"],
      refundSub:
        "Start earning 1% margin on your e-commerce without changing anything!",
      carouselTitle: "Popular Intergrations",
      controlAreaTitle: "Everything our audit covers",
      controlAreaSub:
        "Here are some examples of what our algorithm analyses for each shipment.",
      controlAreaList: [
        "Delivery issues",
        "Unused labels",
        "Incorrect surcharges",
        "Billing Errors",
      ],
      insightsTitle: "A no-code solution",
      trackTitle: "Share your carrier credentials",
      trackBody:
        "Whenever your carrier invoice lands, we automatically start doing our part.",
      magicTitle: "We do our magic",
      magicBody:
        "Our AI-based algorithms will audit your carrier invoices. Based on 50+ data points, we verify whether you are paying the right amount to your carriers.",
      signTitle: "Save up to 10%",
      signBody:
        "We take care of the entire refund process and get you back the money.",
      pricingPlanTitle: "Only pay for success",
      pricingPlanSub:
        "A pricing plan that fits your needs based on your shipping profile. Just select how many shipments you send a year.",
      pricingPlanMobile:
        "A pricing plan that fits your needs based on your shipping profile. Book a call with one of our advisors to discover the price.",
      rangeDetail: "Packages / month",
      customDetail: "Custom",
      rangeValue: "per packages",
      pricingServicesTitle: "Services Included",
      serviceList: [
        "Life time access",
        "Shipping profile transparent",
        "All your carriers in one place",
        "No code integration",
        "Multi-user access",
        "Pay-as-you-go",
        "24/7 phone and email support",
      ],
      pricingStart: "Book a call",
      pricingMobile: "Start Now",
    },
    cases: {
      subText:
        "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life… or keep paying up to 7x more to find new ones.",
      mainStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
      firstStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
      secondStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
      thirdStudy: {
        title: "Returns: the new ecom growth formula",
        subText:
          "Growing brands know returns are a make-or-break experience. You can get it right and win customers for life",
      },
    },
  },
  homepage: {
    mainBanner: {
      title: "Stop overpaying on your carrier invoices",
      text: "We check your parcel invoices automatically and take care of the refund process.",
      cta: "Start Now",
    },
    team: {
      title: "The Lox Team",
    },
    faq: {
      title: "Frequently Asked Questions",
      carrier: {
        title: "Do you support all major carriers?",
        text: "We support all major carriers. However, if we are missing one - it is a matter of days to integrate the carrier.",
      },
      invoice: {
        title: "Where can I find my invoices?",
        text: "It is different for each carrier. Therefore, we suggest to contact us or your carrier to help you with finding your invoices. We are here to help!",
      },
      trial: {
        title: "Would it be possible to try your product ?",
        text: "We offer a free audit check for one month. You will be able to discover how much you can save without spending any time nor money.",
      },
      upload: {
        title: "How should I deliver my invoice?",
        text: "The easiest way is by just providing your carrier portal login credentials. This way you only have to set it up once. However, uploading a csv, xml or xlsx is also perfectly fine for us!",
      },
      refund: {
        title: "Do you take care of getting the credit back?",
        text: "Yes, we do! You do not need to worry of spending any time talking to your carriers. We will take care of everything!",
      },
      validation: {
        title: "How do I know that I get my credit back?",
        text: "You can find all your credits received in a transparent dashboard. Easy right?",
      },
    },
    solutions: {
      invoice: {
        title: "Share your invoices",
        text: "We fetch your invoices directly from your carrier platform.",
      },
      magic: {
        title: "We do our magic",
        text: "Our AI-based algorithms will audit your carrier invoices.",
      },
      save: {
        title: "Save up to 10%",
        text: "We take care of the entire refund process and you get the money back.",
      },
    },
    banners: {
      title: "The Solution",
      refund: {
        title: "Pinpoint the issues",
        text: "Based on 50+ data points, we verify whether you are paying the right amount to your carriers. Think of lost, damaged,  or just the incorrect price invoiced.",
      },
      issues: {
        title: "Your refund process covered",
        text: "At Lox, we take care of the complete refund process.\nRequesting, chasing and following up on each single shipment. \nWe make sure that you receive all your money back.",
      },
      shipping: {
        title: "Your shipping profile transparent",
        text: "Your carrier invoices tell you everything about your logistical network. \nUnderstand your shipping profile and spend with intuitive dashboarding. \nAll your carriers combined in one transparent overview.",
      },
    },
    pricing: {
      title: "Only pay for success!",
      oneMonthTrial: "One month access",
      premium: "Premium",
      free: "Free",
      monthly: "Monthly",
      popular: "Popular Choice",
      getStarted: "Start Now",
      subvalue: "On your refunds",
      usp: {
        oneMonth: "One month access",
        invoice: "Invoice audit on 50+ errors",
        singleUser: "Single-user access",
        successFee: "Success-fee based",
        shipping: "Shipping profile transparent",
        multiCarrier: "Multi-carrier Integration",
        multiUser: "Multi-user access",
        support: "24/7 phone and email support",
        lifeTime: "Life time access",
        cancel: "Cancel any time ",
        refund: "Refund process taken care of",
      },
    },
    testimonials: {
      title: "They trust Lox",
      testimonial1: {
        description:
          "Lox provides full support on our carrier refunds. Additionally, the dashboarding allows us to have a strategic overview in our logistics.",
        company: "CFO ,  Bax",
        origin: "en",
      },
      testimonial2: {
        description:
          "At Helloprint, we realised that we were overpaying our carriers for years. Lox took care of the process, which realised a €100.000  saving in 2020.",
        company: "CFO ,  Helloprint",
        origin: "en",
      },
      testimonial3: {
        description:
          "Lox helped us making sure that we pay the right amount to our carriers. They helped us identify, request and receive the amount that we got overcharged for.",
        company: "Manager Customer Service ,  Tuincentrum",
        origin: "en",
      },
      testimonial4: {
        description:
          "I discovered Lox in 2020, this tool allowed us to save 9% on our carrier invoices. The solution is easy to set up, it allows us to automate complaints about delivery issues such as: Damaged packages, Lost packages, Residenidsial surcharges, Label not used. Lox is the solution that saves time and money.",
        company: "Operations Associate ,  Adesa",
        origin: "fr",
      },
      testimonial5: {
        description:
          "By taking care of our refund process, Lox allowed us to save thousands of euros on our shipping costs!  Moreover, thanks to their data analysis platform we have a clear follow-up of our logistic expenses and how to reduce our costs.",
        company: "Project Manager ,  Japhy",
        origin: "fr",
      },
    },
    contact: {
      title: "Let’s exchange!",
      text: "We'll get back to you within 24 hours!",
      button: "Send message",
      successMessage: "We will come back to you as soon as possible.",
      successTitle: "Email sent!",
      email: "Your Email",
    },
  },
  forgot: {
    title: "Trouble logging in?",
    subTitle: "Please, enter the email address related with your account and we'll send you all the instructions to reset your password.",
    remeber: "Remeber it?",
    login: "Login",
  },
  reset: {
    title: "Reset Password",
    description: "Please enter a password, in order to reset your current account's credentials. Be aware from here on that this password will be used.",
  },
  register: {
    title: "Create Account",
    subTitle: "Welcome to Lox!",
    haveAccount: "Already have an account ?",
    normalSignUp: "Create your Lox Account",
    googleSignUp: "Sign up with Google",
    link: "Sign In",
    button: "Create Account",
    verification: {
      resend: "Resend verification code",
      description: "Check the email we resent a code",
      sent: "We've sent you an email with a verification code to",
      inputCode: "Input your code here",
      noResult: "Didn't receive a code?",
    },
    thanks: "Thank you for creating an account!",
  },
  login: {
    title: "Welcome",
    subTitle: "Sign in to continue to Lox.",
    noAccount: "Don't have an account?",
    google: "Log in with Google",
    link: "Signup Now",
    button: "Sign In",
    signUp: "Sign Up",
  },
  inputs: {
    email: "Email",
    newEmail: "Enter New Email Address",
    firstName: "First Name",
    lastName: "Last Name",
    memberEmail: "Member's Email",
    memberFirstName: "Member's First Name",
    memberLastName: "Member's Last Name",
    company: "Company",
    companyName: "Company Name",
    recipientEmail: "Recipient Emails",
    jobTitle: "Job Title",
    phone: "Phone Number",
    thirdParty: "Third Party",
    password: "Password",
    premission: [
      "Check here to indicate that you have read and agree to the ",
      "Terms and Conditions",
    ],
    forgotPassword: "Forgot Password?",
    newPassword: "New Password",
    verifyPassword: "Verify Password",
    changePassword: "Change Password",
    formatError: "format is not valid.",
    requiredError: "is required.",
    NbCharacters: "At least {nb} characters",
    NbUppercase: "At least {nb} uppercase",
    NbLowercase: "At least {nb} lowercase",
    NbDigit: "At least {nb} digit",
    rememberMe: "Remember Me",
    matchError: "Please make sure that both passwords match before moving on.",
    requireCarrier: "Please select a carrier.",
    requireUser:
      "Please fill in a username associated with your carrier account.",
    requirePassword:
      "Please leave us your password associated with your carrier account.",
    comment: "Your Comment",
    document: "Document type",
    totalFiles: "Total Documents",
    formats: "Please upload documents in following formats",
    passwordRequirements: {
      length: "Must contain at least 8 characters",
      capital: "Must contain a Upper Case character",
      lower: "Must contain a Lower Case character",
      digit: "Must contain a digit",
    },
  },
  carrierInfo: {
    ups: {
      credential: "In order to integrate UPS we only need access to your carrier platform. These credentials must have access to the billing platform and the Quantium view if you are using UPS Express",
      document: "",
    },
    chronopost: {
      credential: "In order to integrate Chronopost we will need your credentials of the platform link you'll find below. We will also need your invoices of the 12 past months in CSV format. For the coming invoices, we will make a request to Chronopost to be in the loop of the reception.",
      document: "In order to audit Chronopost we will need the invoices of the 12 previous months in CSV format.",
    },
    colissimo: {
      credential: "In order to integrate Colissimo we will only need to have access to your carrier credentials  on the platform link you'll find below. If you have multiple credentials feel free to add them all. Make sure that you are adding one credential where we can find the invoices.",
      document: "In order to audit Chronopost we will need the invoices of the 12 previous months in CSV format.",
    },
    colisPrive: {
      credential: "In order to integrate Colis Privé we will need to have access to your carrier credentials on the platform link you'll find below. We will also need your carrier invoices in CSV format for the 12 previous months.",
      document: "Please upload here the invoices of the 12 previous months in CSV format.",
    },
    default: {
      credential: "In order to better understand your carrier please give us all the information to help ease the process.",
      document: "Please upload your carrier invoices here. CSV format will be the ideal format but you can also upload them in other format.",
    },
  },
  cta: {
    login: "Login",
    register: "Register",
    signup: "Signup",
    now: "now",
    forgotPassword: "Send Email",
    remember: "Remember It ?",
    nextProcess: "Next Step",
    next: "Next",
    magic: "Start the Magic",
    back: "Back",
    saveAsDraft: "Save As Draft",
    reSend: "Re-send",
    done: "Done",
    readMore: "Read More",
    addCompany: "Register New Company",
    save: "Save",
    saveStatus: "Save Status",
    split: "Split",
    addCase: "New Study",
    add: "Add",
    admin: "Admin Panel",
    revert: "revert",
    send: "Send",
    pay: "Pay",
    pdf: "PDF",
    csv: "CSV",
    view: "View",
    pdfStorage: "PDF Storage",
    saveMember: "Save Member",
    validate: "Validate",
    bulkUpload: "Bulk Uploading",
    sendFiles: "Send Files",
    continue: "Continue",
    connect: "Connect",
    disconnect: "Disconnect",
    issue: "Report Issue",
    other: "Other",
    claiming: "Claim",
    runs: "Run",
    yes: "Yes",
    no: "No",
    sendMail: "Send Mail",
    deleteCredential: "Delete",
    testSendOut: "Test Email Send Out",
    triggerSendOut: "Trigger Send Out",
    addCompanyEmail: "Add Company to Send Out List",
    updateCompanyEmail: "Update Company Send Out List",
    deleteRecipient: "Delete Recipient",
    deactivateClient: "Deactivate Client",
    activateClient: "Activate Client",
    backToEmails: "Back to Email Selection",
    verify: "Verify",
    sendCode: "Send Code",
    createAccount: "Create Account",
    saveCarrier: "Save Carrier",
    finish: "Finish",
    addBilling: "Add Billing Info",
    sendCode: "Send verification code",
    resetPassword: "Reset Password",
    resendCode: "Resend Code",
    dataStored: "View Data Stored",
    reasonsToClaim: "Reasons To Claim",
    adjustActivity: "Adjust Activity",
    sendCode: "Send Code",
    resetPassword: "Reset Password",
    allCarriers: "All Carriers",
    allIssues: "All Issues",
    redirectToLogin: "Redirect To Login",
    redirectToHome: "Redirect To Home",
    letsGo: "Lets Go!",
    skipTour: "Skip Tour",
    seeNow: "See Now",
    end: "End",
    tourUpdates: "Tour Updates",
    clearAll: "Clear All",
    addMoreGoods: "Add More Goods",
    dataNeededPage: "Go to Action Required Page",
    investigationDetailsPage: "Go to Investigation Details Page",
    previous: "Previous",
    export: "Export",
    sending: "Sending...",
    betaUpdates: "See all latest BETA updates !",
    betaHistory: "BETA updates",
    beta: "Beta",
    notification: "Notification",
    showMore: "Show more",
    showLess: "Show less",
    changePlan: "Change Plan",
    viewBreakdown: "View breakdown",
    sendReply: "Send a reply to the carrier",
    stop: "Stop",
    addFavourite: "Add to Favourites",
  },
  pageNotFound: {
    title: "Page Not Found",
    text: "Uh oh, we can't seem to find the page you're looking for. Get redirected back to safety with the link below.",
  },
  unauthorized: {
    title: "Unauthorized",
    text: "Uh oh, it seems you don't have the right permissions to access this page. Get redirected back to the home page by clicking below."
  },
  terms: {
    tAndCs: "Terms & Conditions",
    data: "Data Policy",
  },
  message: {
    default: {
      success: {
        title: "Success",
        text: "This is default text please replace me",
      },
    },
    oneInvoice: {
      title: "More than one invoice?",
      text: "Only one invoice can be uploaded per claim. However there is another file dropzone labelled Add Supporting Documents, here you can upload multiple files. \n\nIf you would like to replace the current file with this file please confirm below.",
      confirm: "Replace File",
      cancel: "Keep Original",
    },
    unsavedChanges: {
      title: "Looks like you forgot to save some data.",
      text: "It seems you have made changes without saving. Upon leaving this page all your data will be deleted.",
      confirm: "Continue editing",
      cancel: "Discard changes",
    },
    billingInformation: {
      title: "Update Successful!",
      text: "Your billing information has been updated."
    },
    mailSent: {
      title: "Email Sent",
      text: "Please locate the email and follow the instructions to change your password. We have sent you an email with all the instructions to reset your password.",
    },
    noInformation: {
      title: "Oops!",
      text: "Please be sure to fill in all fields!",
    },
    incorrectHeaders: {
      title: "Oops!",
      text: "It seems you uploaded a file with incorrect column header names. Please re-download the template to check the correct headers against your own.",
    },
    registerComplete: {
      info: [
        "Thank you for your registration!",
        "We've sent you an email with a verification code.",
        "Please provide the digit code from the email and insert it in the box below.",
        "success",
      ],
      cancel: "Didn't receive Code",
      confirm: "Received Code",
    },
    trackingNumberExists: {
      dataNeeded: "Seems like a claim with this tracking number already exists in the Action Required Page. You can upload the invoices there instead.",
      investigationDetails: "Seems like a claim with this tracking number already exists in the Investigation Details Page. You can view the claim here instead.",
      validating: "Validating Tracking Number...",
    },
    significantAmount: {
      total: "Warning: Total price is significantly high. Make sure all values are entered correctly before proceeding.",
      tax: "Warning: Exceeds above standard value",
    },
    requiredFields: {
      general: "This is a mandatory field.",
      description: "Please add the description of Goods",
      number: "Please add the number of Goods.",
      unit: "Please add the unit price of each Good.",
      tax: "Please add the tax percentage of Goods.",
    },
    validation: {
      zip: "Please enter a postal code valid for the selected city.",
      email: "Please enter a valid email address.",
      url: "Please enter a valid url."
    },
    noCodeReceived: {
      title: "We have been notified",
      text: "We are working on getting you access to the platform",
    },
    passwordError: [
      "Oops!",
      "The processing of your password couldn't be handled! Please re-check information and try again.",
    ],
    contactLox: [
      "Have any questions?",
      "Send us a message and we will get back to you via email as soon as possible.",
      "Enter your message...",
    ],
    passwordUpdated: [
      "Updated!",
      "You password has been updated... Don't forget it!",
      "We have sent you a email, with all necessary steps to move forward!",
    ],
    registerError: [
      "Oops!",
      "Please make sure all requirements are fulfilled.",
      "error",
    ],
    noDataRetrieved: {
      title: "Oops! No data available",
      text: "We apologize, but it seems that there was an issue retrieving the information you requested. If the problem persists, please contact our support team for assistance. We appreciate your understanding and apologize for any inconvenience caused."
    },
    registrationFailure: {
      title: "Something went wrong",
      text: "Registration was impossible. Please try again later. If the error keeps occurring please contact our team"
    },
    loginFailure: {
      title: "Something went wrong",
      text: "Log in was impossible. Please try again later. If the error keeps occurring please contact our team",
      confirmButton: "Contact our team",
    },
    passwordAuthenticationNotEnabled: {
      title: "Looks like we can't find you.",
      text: "It seems that the credentials you've entered are not available in our system. Please contact us via support@loxsolution.com.",
    },
    accessGranted: "'s access has been updated!",
    invoiceUploaded: [
      "Success!",
      "Your invoice(s) have been uploaded.",
      "success",
    ],
    credentialsUploaded: ["Added!", "Your credential has been well added."],
    credentialsUpdated: ["Updated!", "Your credential has been well updated."],
    credentialsDeleted: ["Deleted!", "Your credential has been deleted."],
    confirmDelete: [
      "Are you sure?",
      "You won't be able to revert this!",
      "Yes, delete it!",
    ],
    memberDeleted: ["Bye Bye!", "Member successfully deleted!"],
    notAdded: {
      title: " couldn't be added!",
      text: " is already taken!",
    },
    // Messages for confirming high amount inputs
    confirmAmount: {
      title: "Are you sure?",
      text: "We noticed that you put {amount} in the amount field. We wanted to make sure that it was the correct value you wanted to insert.",
      confirmButton: "The value is correct",
      cancelButton: "It is the wrong value",
    },
    // Message for when the inclusive amount is less than the exclusive amount
    wrongInclusiveAmount: {
      title: "Incorrect Information",
      text: "The amount inclusive cannot be lower than the amount exclusive. Please double check these amounts.",
    },
    clientAccess: {
      title: "No Admin Access!",
      text: "Please be aware that editing, deleting and adding of credentials can only be done by the client.",
    },
    genericError: [
      "Oops!",
      "Something in our system went wrong, we have been contacted and are working on it!",
      "Email wasn't found unfortunately, you have",
      " chances left till we block your account.",
    ],
    teamMemberAdded: [
      " added successfully!",
      " coudln't be added!",
      " is already taken.",
    ],
    uploadError: [
      "Oops!",
      "There was a problem with the uploading of the file. Please try again. If the the problem remains you can send your invoice at customersuccess@loxsolution.com",
    ],
    noDataUploadError: {
      title: "Oops!",
      text: "It seems you uploaded a file with no extra data submitted. Please make sure you fill in all the columns for a tracking number.",
    },
    userBlocked: [
      "To many Tries!",
      "You have been blocked from our platform. If you would like to be re-registered please get hold of us on customersuccess@loxsolution.com",
    ],
    emailSent: {
      title: "Email Sent!",
      sub_text:
        "Your message has landed in our inbox. One of our trained Loxtranauts will be with you shortly.",
    },
    claimCreated: {
      title: "Claim Created Successfully",
      text: "Your claim will be processed within 24 hours. You’ll be able to see updates via the Claim details page or the My Claims Page.",
    },
    claimNotCreated: {
      title: "Unsuccessful Claim Creation",
      data: {
        text: "The information attached with your claim couldn't be uploaded due to an unexpected issue.",
      },
      documents: {
        text: "The documents attached with your claim couldn't be uploaded due to an unexpected issue.",
      },
    },
    emailError: {
      title: "Oops!",
      sub_text:
        "Something in the email system went wrong. We have been contacted and are on it.",
    },
    loading: {
      register: "Retrieving all your information!",
    },
    loginErrors: {
      title: "Oops!",
      status_403:
        "Your account still needs to be processed. We will keep you in touch very soon.",
      status_401: "Invalid username or password.",
    },
    companyExsists: {
      title: "Company is already in use.",
      textBlock:
        "We have found that the company that you have entered, already has an admin. Please ask them to add you via the team members page.",
    },
    carrierCredentials: {
      title: "No Credentials to Claim",
      text: "To enter this page and use its features we are first in need of your carrier credentials. This will help us claim on your behalf.",
      confirmButton: "Go to Carrier Info Page",
    },
    lockedProducts: {
      title: "We are looking into this product!",
      text: "If you have any interest in this product please let us know via the feedback section in the sidebar.",
    },
    lockedDashboard: {
      title: "Unfortunately you don't have access!",
      text: "Not to worry, if you would like further information on our Dashboarding product please get in contact with on of our amazing team members at customersuccess@loxsolution.com",
    },
    noData: {
      title: "Invalid Search",
      text: "The filters you entered gathered zero results. Please broaden your search in order to retreive the required results.",
    },
    profileUpdate: {
      title: "Good Job!",
      text: "You updated your profile.",
    },
    profileUpdateFailure: {
      title: "Oops!",
      text: "Your profile wasn't updated, due to technical issue. Please retry and if the problem presists please let us know in our feedback section.",
    },
    duplicateFile: {
      title: "Duplicate File Found!",
      text: " has been droppped in the dropzone more than once. We have removed the duplicates and kept the original file.",
    },
    verifyEmail: {
      subject: "Verify your email",
      title: "Thank you for registering with Lox!",
      addressed: "Good Day",
      message:
        "This email is serving as a verification method. Please find your verification code below, copy and paste it in the text input provided on our website where you previously registered.",
      verification: "Here is your verification code: ",
      finishing: "Kind Regards",
      from: "The Lox Team",
      error:
        "Code was not verified, please re-check your code and try again. Our tech team has been notified and we are looking into it as we speak!",
      success:
        "Welcome to the Lox Community. We will now ask you for some details to set-up your account.",
      emailExsists: [
        "Oops!",
        "It seems you have entered the same email as one of our current users. Please re-check, and if the problem persists please send us an email at customersuccess@loxsolution.com!",
      ],
      emailNotFound:
        "It seems the email address you have enterred is not present in our Database. Please carefully re-check and try again.",
    },
    api: {
      success: {
        title: "Amazing 🌟",
        text: "Our team is currently checking if everything is good. We'll send you an email within a few days.",
      },
      failed: {
        text: "The config you sent us wasn't saved. Please try again and if the problem persists please send us a email at customersuccess@loxsolution.com"
      },
      disconnected: {
        title: "API Disconnected 🌟",
        text: "We will no longer make requests with the API configuration given. Please simply set up the API configuration again if you would like us to continue requesting."
      },
    },
    cancelApi: {
      cancelTitle: "Are you sure you want to cancel API integration?",
      disconnectTitle: "Are you sure you want to disconnect the API integration?",
      text: "No problem, you are always able to continue the API integration in the future!",
    },
    manualUpload: {
      title: "Document shared successfully!",
      text: "Your changes would appears within 24 hours.",
    },
    uploadFailed: {
      title: "Oops!",
      text: "The file failed to upload. Please try again and if the problem persists please send us a email at customersuccess@loxsolution.com"
    },
    templateFailed: "The template couldn't be downloaded, please try again and if the problem persists please send us a email at customersuccess@loxsolution.com",
    helpSetupAPI: {
      problem1: "I can't setup my API.",
      problem2: "I don't understand the documentation.",
    },
    helpProcessAPI: {
      problem1: "Where does the integration stand?",
      problem2: "I need to change my API config",
    },
    helpConnected: {
      problem1: "What document types are accepted?",
      problem2: "Can I have access to my dropped documents?",
    },
    requestSent: "Request for help sent",
    messageSent: "Our support team has been informed of your situation. They will contact you within 48 hours. We apologize for the inconvenience caused.",
    messageNotSent: "The message failed to reach our support team. Please try again and if the problem persists please send us a email at customersuccess@loxsolution.com",
    notAdmin: {
      title: "Unauthorized!",
      text: "You need admin rights for access to this page.",
    },
    accessDenied: {
      title: "Access Denied!",
      home: {
        text: "You need access to the dashboarding product to enter this page.",
      },
      PasswordRequest: {
        text: "You need to have requested a password reset to access this page."
      },
    },
    overwriteClaim: {
      title: "Claim already Exists!",
      text: "If you would like to overwrite the previous claim on this tracking number simply click yes, or click no to keep the previous version.",
    },
    successfulClaim: {
      text: "Our team is currently working on processing your Claim, you will be notified accordingly.",
    },
    wrongFileType: {
        title: "Incorrect Document Type!",
        text: "The file given isn't of the correct type, please be sure to upload the the file in { types } formats.",
    },
    claimHistoryActionReceived:{
      title: "Action Received",
      text: "It will take approximately 24 hours for the action to reflect on the carrier platform.",
    },
  },
  feedback: {
    email: "Please make sure to fill in your Email.",
    password: "Please make sure to fill in your Password.",
    firstName: "Please make sure to fill in your First Name.",
    lastName: "Please make sure to fill in your Last Name.",
    company: "Please make sure to fill in your Company.",
    jobTitle: "Please make sure to fill in your Job Title.",
    phone: "Please make sure to fill in your Phone Number.",
    noData: "No data found matching your input.",
    required: {
      inputs: "This is a required field.",
      email: "Invalid email address",
      document: "Document is needed for claim creation."
    },
    invalidAmountInclusive: "Inclusive cannot be less than exclusive",
    invalidDate: "Invalid Date Range: Please enter a date between one year ago and yesterday's date.",
  },
  processed: {
    awaiting: {
      title: "Welcome to the Lox Community!",
      explanation:
        "We are still busy processing all the data you have made available to Lox. Once everything is ready, we will inform you. This will usually take a few days.",
    },
    noUploads: {
      title: "Welcome to the Lox Community!",
      explanation:
        "We noticed that you haven't uploaded any Carrier Credentials or Invoices. This means you won't be able to view any data under your company's name. Please contact us via the Need Help Button below if you are having any issues.",
    },
    concerns: {
      title: "Need Help?",
    },
  },
  graphDescriptions: {
    home: "( Total Amount Invoiced per Carrier )",
    refunds: "( Total Credits per Carrier )",
  },
  generatedInvoice: {
    invoice: "Invoice",
    subTotal: "Subtotal",
    address: "Address",
    zipCode: "Zip Code",
    country: "Country",
    invoiceNumber: "Invoice Number",
    shippingAddress: "Shipping Address",
    orderDetails: "Order Details",
    orderDate: "Order Date",
    billingAddress: "Billing Address",
    vat: "VAT",
    clientName: "Client Name",
    clientStreet: "456 Client St",
    clientPostalCode: "67890",
    clientCity: "Client City",
    clientCountry: "Client Country",
    description: "description",
    quantity: "quantity",
    unitPrice: "unit price",
    totalPrice: "total price",
    companyInformation: "Company information",
    clientInformation: "Client information",
    clientName: "Client name",
    productDetails: "Product details",
    companyInformation: "Company information",
    companyAddress: "Company address",
  },
};
